import {
    Directive,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    ElementRef,
} from '@angular/core'
import { debounceTime, Observable, Subscription } from 'rxjs'

@Directive({
    selector: '[appObserveElement]',
    exportAs: 'intersection',
})
export class ObserveElementDirective implements OnInit, OnDestroy {

    @Input()
    public root: HTMLElement | null = null

    @Input()
    public rootMargin = '0px 0px 0px 0px'

    @Input()
    public threshold = 0

    @Input()
    public debounceTime = 500

    @Input()
    public isContinuous = false

    @Output()
    public isIntersecting = new EventEmitter<boolean>()

    public intersectionState = false
    public subscription: Subscription

    constructor(
        private element: ElementRef,
    ) {
    }

    public ngOnInit(): void {
        this.subscription = this.createAndObserve()
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }

    public createAndObserve(): Subscription {
        if (typeof window === 'undefined' || ! ('IntersectionObserver' in window)) {
            return new Observable<boolean>(subscriber => {
                subscriber.next(false)
                subscriber.complete()
            }).subscribe()
        }

        const options: IntersectionObserverInit = {
            root: this.root,
            rootMargin: this.rootMargin,
            threshold: this.threshold,
        }

        return new Observable<boolean>(subscriber => {
            const intersectionObserver = new IntersectionObserver(entries => {
                const { isIntersecting } = entries[0]
                subscriber.next(isIntersecting)

                if (isIntersecting && ! this.isContinuous) {
                    intersectionObserver.disconnect()
                }
            }, options)

            intersectionObserver.observe(this.element.nativeElement)

            return {
                unsubscribe() {
                    intersectionObserver.disconnect()
                },
            }
        })
            .pipe(debounceTime(this.debounceTime))
            .subscribe(status => {
                this.isIntersecting.emit(status)
                this.intersectionState = status
            })
    }

}
